/** 
 * @Desc: 组件--video.js组件封装
 */
<template>
  <div
    id="videoBox"
    :class="!videoObj ? 'video-loading' : ''"
    @mouseenter="isLogin && myPlayer && _videoBoxMouseenter($event)"
    @mouseleave="isLogin && myPlayer && _videoBoxMouseleave($event)"
    @mousemove="isLogin && myPlayer && _videoBoxMousemove($event)"
  >
    <img
      v-if="!isLogin && videoObj && !videoObj.isTrailer"
      class="img-cover"
      :src="videoObj.coverUrl"
      alt=""
    >
    <!-- 收藏 -->
    <template v-if="false && isLogin && videoObj">
      <transition name="el-fade-in-linear">
        <img
          v-show="collectionShow && videoObj.isCollected"
          class="img-collection"
          src="../../assets/new_ui/collection-cancel.png"
          alt=""
          title="取消收藏"
          @click.stop="$emit('handlerCollection', videoObj)"
        >
      </transition>
      <transition name="el-fade-in-linear">
        <img
          v-show="collectionShow && !videoObj.isCollected"
          class="img-collection"
          src="../../assets/new_ui/collection.png"
          alt=""
          title="收藏"
          @click.stop="$emit('handlerCollection', videoObj)"
        >
      </transition>
    </template>
  </div>
</template>
<script>
import { debounce, GetMousePos, localStorageUtil } from "utils/util";
import { SetVideoStatistics } from "api/common";
import { originUrl } from "config/constant";
import { mapState } from "vuex";
export default {
  props: {
    videoObj: {
      type: Object
    },
    resourceId: {
      type: [String, Number]
    },
    videoUrl: {
      type: String,
      default: ""
    },
    coverUrl: {
      type: String,
      default: ""
    },
    trailerDuration: {
      type: Number
    },
    isTrailer: {
      type: Boolean,
      default: false
    },
    hadLogo: {
      // 是否有logo：true-有 false-没有
      type: Boolean,
      default: true
    },
    level: {
      // level
      type: [Number, String],
      default: 2
    },
    subject: {
      // subject
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      refresh: true,
      myPlayer: null,
      timer: null,
      timer1: null,
      timerNext: null,
      collectionShow: false,
      // isLogin: true,
      isM3u8: false
    };
  },
  watch: {
    videoObj: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        if (!val) {
          if (this.myPlayer) {
            this.myPlayer.dispose();
            this.myPlayer = null;
          }
          if ($("#videoBox #videoJs")[0]) {
            $("#videoBox #videoJs").remove();
          }
        } else if (
          val &&
          oldVal &&
          val.resourceId === oldVal.resourceId &&
          val.isCollected !== oldVal.isCollected
        ) {
        } else if (val && oldVal && oldVal !== undefined) {
          this.refresh = false;

          setTimeout(() => {
            this.refresh = true;
            this.$nextTick(() => {
              this._handlerEvent(val);
            });
          }, 50);
        } else if (val && val.resourceId) {
          this.$nextTick(() => {
            this._handlerEvent(val);
          });
        }
      }
    }
  },
  computed: {
    ...mapState(["accountInfo", "isLogin"])
  },
  methods: {
    /**
     * 资源初始化时需要做的操作
     * **/
    _handlerEvent(data) {
      let {
        resourceId,
        title,
        resourcePackingTitle,
        videoUrl,
        coverUrl,
        width,
        height,
        isTrailer,
        trailerDuration,
        hadLogo
      } = data;
      this.isM3u8 =
        resourceId == 50150 &&
        (originUrl.indexOf("http://family.viewshare.net") !== -1 ||
          originUrl.indexOf("localhost:") !== -1)
          ? true
          : false;
      // this.isM3u8 = true;
      let _this = this;
      if (this.myPlayer) {
        this.myPlayer.dispose();
        this.myPlayer = null;
      }
      if (this.timerNext) {
        clearTimeout(this.timerNext);
        this.timerNext = null;
      }
      if (!this.isLogin && !isTrailer) {
        return;
      }
      $("#videoBox").prepend(
        '<video  id="videoJs" class="video-js vjs-default-skin vjs-big-play-centered" ></video>'
      );
      let vjs_volume = this._vjsVolume("get");
      // console.log(this.isM3u8, videoUrl, data);
      this.myPlayer = this.$video(
        "videoJs",
        {
          playbackRates: [0.5, 1, 1.5, 2], //切换播放速率按钮
          //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
          controls: true,
          //自动播放属性,muted:静音播放
          muted: vjs_volume ? false : true,
          autoplay: true,
          //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
          preload: "auto",
          // fluid: true, // 自适应宽高
          //设置视频播放器的显示宽度（以像素为单位）
          width: width,
          // 设置视频播放器的显示高度（以像素为单位）
          height: height,
          // url
          poster: coverUrl, // 封面图片
          sources: [
            {
              src: this.isM3u8
                ? "http://family.viewshare.net/fullVideo/50151/demo5/master.m3u8"
                : videoUrl
            }
          ],
          controlBar: {
            // 设置控制条组件
            /* 设置控制条里面组件的相关属性及显示与否  */
            playToggle: true,
            progressControl: true,
            currentTimeDisplay: true,
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            audioTrackButton: true,
            volumePanel: {
              inline: false
            },

            /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
            children: [
              { name: "playToggle" }, // 播放/暂停按钮
              { name: "currentTimeDisplay" }, // 视频当前已播放时间
              { name: "timeDivider" }, //
              { name: "durationDisplay" }, // 视频播放总时间
              { name: "progressControl" }, // 播放进度条
              { name: "subsCapsButton" }, // 切换字幕
              { name: "audioTrackButton" }, // 切换音轨
              {
                // 倍数播放，可以自己设置
                name: "playbackRateMenuButton",
                playbackRates: [0.5, 1, 1.5, 2]
              },
              {
                name: "volumePanel", // 音量控制
                inline: false, // 不使用水平方式
                volume: vjs_volume
              },
              { name: "FullscreenToggle" } // 全屏
            ]
          }
        },
        function onPlayerReady() {
          _this.myPlayer.log("Your player is ready!"); // 比如： 播放量+1请求
          _this.myPlayer.tech_.off("dblclick"); // 禁掉双击全屏
          _this._addNextEle(); // 添加播放下一个按钮
          _this._setBtnTitle(); // 重新设置控制条btn的title
          $(".vjs-control-bar").prepend(
            `<span class="vjs-video-title no-wrap">${resourcePackingTitle}：${title}</span>`
          ); // 添加资源标题
          SetVideoStatistics({ resourceId: resourceId }); // 添加统计
          this.volume(vjs_volume);
          this.playbackRate(_this._vjsRate("get"));
          this.on("volumechange", function(e) {
            _this._vjsVolume("set");
          });
          this.on("ratechange", function(e) {
            _this._vjsRate("set");
          });
          this.on("timeupdate", function() {
            if (_this.timerNext) {
              clearTimeout(_this.timerNext);
              _this.timerNext = null;
            }
          });
          this.on("ended", function() {
            return;
            _this.timerNext = setTimeout(() => {
              _this.$emit("handlerNext");
              clearTimeout(_this.timerNext);
              _this.timerNext = null;
            }, 2000);

            // _this.myPlayer.log("Awww...over so soon?!");
          });
        }
      );
    },

    /**
     * 系统当前记录的音量
     * **/
    _vjsVolume(type) {
      if (type === "get") {
        let vjs_volume = localStorageUtil.getItem("vjs_volume");
        if (
          vjs_volume === "" ||
          vjs_volume === undefined ||
          vjs_volume === "undefined"
        ) {
          vjs_volume = 1;
          localStorageUtil.setItem("vjs_volume", 1);
        } else {
          vjs_volume = Number(vjs_volume);
        }
        return vjs_volume;
      } else {
        let _ele = document.querySelector("#videoJs video");
        let volume = _ele.volume;
        let muted = _ele.muted;
        localStorageUtil.setItem("vjs_volume", muted ? 0 : volume);
      }
    },

    /**
     * 系统当前记录的倍速
     * **/
    _vjsRate(type) {
      if (type === "get") {
        let vjs_rate = localStorageUtil.getItem("vjs_rate");
        if (
          vjs_rate === "" ||
          vjs_rate === undefined ||
          vjs_rate === "undefined"
        ) {
          vjs_rate = 1;
          localStorageUtil.setItem("vjs_rate", 1);
        } else {
          vjs_rate = Number(vjs_rate);
        }
        return vjs_rate;
      } else {
        let _ele = document.querySelector("#videoJs video");
        let rate = _ele.playbackRate;
        localStorageUtil.setItem("vjs_rate", rate);
      }
    },

    /**
     * 给播放器添加播放下一个元素
     * **/
    _addNextEle() {
      let _this = this;
      $(".vjs-play-control").after(
        `<button class="vjs-next-control vjs-control ${
          this.videoObj.lastOne ? "vjs-next-control-disabled" : ""
        }" type="button" title="下一个"></button>`
      ); // 添加播放下一个
      if (this.videoObj.lastOne) {
        return;
      }
      $(".vjs-control-bar ").on("click", ".vjs-next-control", function() {
        _this.$emit("handlerNext");
        return;
      });
    },
    /**
     * 重新设置控制条btn的title
     * **/
    _setBtnTitle() {
      $(".vjs-control-bar").on("mouseenter", ".vjs-button", function() {
        let _title = $(this).attr("title");
        let _title1 = _title;
        if (_title === "Play") {
          _title1 = "播放";
        } else if (_title === "Pause") {
          _title1 = "暂停";
        } else if (_title === "rePlay") {
          _title1 = "重新播放";
        } else if (_title === "Playback Rate") {
          _title1 = "倍速";
        } else if (_title === "Unmute" || _title === "Mute") {
          _title1 = "音量";
        } else if (_title === "Fullscreen") {
          _title1 = "全屏";
        } else if (_title === "Non-Fullscreen") {
          _title1 = "退出全屏";
        }
        $(this).attr("title", _title1);
      });
    },

    /**
     * videoBox--鼠标移入
     * **/
    _videoBoxMouseenter(e) {
      return;
      if (this.timer1) {
        clearTimeout(this.timer1);
        this.timer1 = null;
      }
    },
    /**
     * videoBox--鼠标移动
     * **/
    _videoBoxMousemove(e) {
      return;
      let top = $("#videoBox").offset().top;
      let height = $("#videoBox").height();
      let paused = $("#videoJs video")[0]
        ? $("#videoJs video")[0].paused
        : false;
      let pos = GetMousePos(e);
      this.collectionShow = true;
      if (this.timer || !paused) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      if (pos.y > top + height - 160) {
        return;
      }
      this.timer = setTimeout(() => {
        let paused = $("#videoJs video")[0]
          ? $("#videoJs video")[0].paused
          : false;
        if (!paused) {
          this.collectionShow = false;
        }
        clearTimeout(this.timer);
        this.timer = null;
      }, 2600);
    },

    /**
     * videoBox--鼠标移出
     * **/
    _videoBoxMouseleave() {
      return;
      let paused = $("#videoJs video")[0]
        ? $("#videoJs video")[0].paused
        : false;
      if (this.timer1 || !paused) {
        clearTimeout(this.timer1);
        this.timer1 = null;
      }
      this.timer1 = setTimeout(() => {
        let paused = $("#videoJs video")[0]
          ? $("#videoJs video")[0].paused
          : false;
        if (!paused) {
          this.collectionShow = false;
        }
        clearTimeout(this.timer1);
        this.timer1 = null;
      }, 2600);
    },
    /**
     * 键盘事件
     * **/
    _keyUp(e) {
      if (this.myPlayer) {
        let _current = 0;
        switch (e.keyCode) {
          case 32: // 空格键 暂停/播放
            let _ele = document.querySelector("#videoJs video");
            if (_ele.paused) {
              _ele.play();
            } else {
              _ele.pause();
            }
            break;
          case 37: // 后退
            _current = this.myPlayer.currentTime();
            this.myPlayer.currentTime(_current <= 5 ? 0 : _current - 5);
            break;
          case 39: // 前进
            if (this.myPlayer.ended()) {
              return;
            }
            _current = this.myPlayer.currentTime();
            let _total = this.myPlayer.duration();
            this.myPlayer.currentTime(
              _total - _current <= 5 ? _total : _current + 5
            );
            break;
          default:
            return;
        }
      }
    },
    /**
     * 组件销毁前需销毁的一些方法
     * **/
    _beforeDistoryReset() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      if (this.timer1) {
        clearTimeout(this.timer1);
        this.timer1 = null;
      }
      if (this.timerNext) {
        clearTimeout(this.timerNext);
        this.timerNext = null;
      }
      if (this.myPlayer) {
        this.myPlayer.dispose();
        this.myPlayer = null;
      }
      if ($("#videoBox #videoJs")[0]) {
        $("#videoBox #videoJs").remove();
      }
    }
  },
  created() {
    this.$nextTick(() => {
      window.addEventListener("keyup", this._keyUp);
    });
  },
  destroyed() {
    window.removeEventListener("keyup", this._keyUp);
    this._beforeDistoryReset();
  }
};
</script>
<style lang="less">
#videoBox {
  // width: 12.23rem;
  // height: 6.88rem;
  height: 7.13rem;
  width: 100%;
  // height: 100%;
  position: relative;
  // margin-left: 20px;
  border-radius: 0.3rem;
  overflow: hidden;

  .img-cover {
    width: 100%;
    height: 100%;
  }
  #videoJs.video-js {
    // border-radius: 30px;
    // overflow: hidden;

    .vjs-control-bar {
      // overflow: hidden;
      width: 100%;
      height: 160px;
      // rem
      height: 1.6rem;
      border-radius: 1px;

      padding: 0 0.2rem;
      box-sizing: border-box;
      width: 100%;
      height: 1.6rem;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      border-radius: 0.3rem;
      // background: rgba(0, 0, 0, 0);

      > .vjs-control {
        width: 0.5rem;
        height: 0.5rem;
        // margin-top: 0.67rem;
        margin-top: 0.97rem;
        // color: #69c984;
        color: #95f280;

        // rem
        width: 0.5rem;
        height: 0.5rem;
        margin-top: 0.97rem;
      }
      .vjs-video-title {
        display: inline-block;
        width: calc(100% - 40px);
        position: absolute;
        left: 0.23rem;
        top: 0.33rem;
        color: #ffffff;
        font-size: 0.26rem;
        font-family: "DFPFangYuanW7";
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
      }
      .vjs-play-control {
        &.vjs-playing {
          background-image: url("../../assets/new_ui/vjs-pause.png");
          background-repeat: no-repeat;
          background-size: 0.5rem 0.5rem;
          // background-position: center;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
        }
        &.vjs-paused {
          background-image: url("../../assets/new_ui/vjs-play.png");
          background-repeat: no-repeat;
          background-size: 0.5rem 0.5rem;
          // background-position: center;
          // margin-left: 10px;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
        }
        &.vjs-ended {
          background-image: url("../../assets/new_ui/vjs-replay.png");
          background-repeat: no-repeat;
          background-size: 0.5rem 0.5rem;
          background-position: center;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
        }
        .vjs-icon-placeholder {
          display: none;
        }
      }
      .vjs-next-control {
        display: inline-block;
        width: 0.5rem;
        // height: 50px;
        // border-radius: 6px;
        background-image: url("../../assets/new_ui/vjs-next.png");
        background-repeat: no-repeat;
        background-size: 0.5rem 0.5rem;
        // background-position: center;
        // background-color: #ffffff;
        margin-left: 0.25rem;
        &:not(.vjs-next-control-disabled):hover {
          filter: saturate(125%) brightness(108%);
        }
        &.vjs-next-control-disabled {
          filter: grayscale(90%);
          cursor: not-allowed;
        }
      }
      .vjs-time-control {
        color: #ffffff;
        font-size: 0.18rem;
        font-family: "DFPFangYuanW7";
        display: inline-block;
        padding: 0 0.06rem;
        line-height: 0.4rem;
        height: 0.4rem;
        margin-top: 1.02rem;
        &.vjs-current-time {
          width: 0.8rem;
          text-align: right;
          // padding-left: 30px;
        }
        &.vjs-duration {
          width: 0.8rem;
          text-align: left;
          // padding-right: 30px;
        }
      }
      .vjs-time-divider {
        padding: 0;
        width: 0.06rem;
        min-width: 0.06rem;
        text-align: center;
        & > div > span {
          color: #ffffff;
        }
      }
      .vjs-progress-control {
        position: absolute;
        width: 100%;
        left: 0;
        // top: -0.4rem;
        top: -0.4rem;
        // margin-top: 60px;
        // margin-top: 0.6rem;
        .vjs-time-tooltip {
          font-family: "DFPFangYuanW7";
        }
        .vjs-progress-holder {
          height: 0.08rem !important;
          border-radius: 0.04rem;
          margin: 0 0.13rem;
          .vjs-time-tooltip {
            // top: -4.5em;
          }
          .vjs-play-progress {
            // background-color: #69c984;
            background-color: #95f280;
            border-radius: 0.04rem;
            &:before {
              transform: scale(0);
              font-size: 0.26rem;
              // color: pink;
              color: transparent;
              background: url("../../assets/new_ui/jindu_yuan.png") no-repeat;
              background-size: 100% 100%;
              top: 50%;
              margin-top: -0.13rem;
              transition: transform 0.2s;
            }
          }
          .vjs-load-progress {
            border-radius: 0.04rem;
          }

          .vjs-mouse-display {
            width: 0 !important;
          }
        }
        .vjs-progress-holder {
          height: 0.5rem;
        }
        &:hover {
          .vjs-progress-holder {
            height: 0.1rem !important;
            .vjs-play-progress {
              height: 0.1rem;
              &:before {
                transform: scale(1);
                font-size: 0.26rem;
                color: transparent;
                background: url("../../assets/new_ui/jindu_yuan.png") no-repeat;
                background-size: 100% 100%;
                border-radius: 50%;
                top: 50%;
                margin-top: -0.13rem;
              }
            }
          }
        }
      }
      .vjs-control.vjs-subs-caps-button,
      .vjs-control.vjs-audio-button,
      .vjs-control.vjs-playback-rate {
        .vjs-menu {
          width: 0.8rem;
          width: 0.64rem;
          .vjs-menu-content {
            // bottom: 24px;
            bottom: 0.35rem;
            border-radius: 0.0861rem;
            width: 100%;
            background: rgba(43, 51, 63, 0.7);
            max-height: 20em !important;
            .vjs-menu-item {
              width: 100%;
              // height: 30px;
              // line-height: 30px;
              height: 0.36rem;
              line-height: 0.36rem;
              padding: 0;
              text-align: center;
              color: #ffffff;
              font-size: 0.16rem;
              font-family: "DFPFangYuanW7";
              &.vjs-selected {
                // color: #69c984;
                color: #95f280;
                background: transparent;
              }
              &:hover {
                color: #ffffff;
                // background: rgba(105, 205, 163, 0.9);
                background: #7dd473;
              }
            }
          }
        }
      }
      .vjs-control.vjs-subs-caps-button {
        // height: 40px !important;

        // background-image: url("../../assets/new_ui/vjs-next.png");
        // background-repeat: no-repeat;
        // background-size: 50px 50px;
        background-position: center;
        padding: 0px 0.35rem;
        position: absolute;
        right: 3rem;
        top: 0.05rem;
        display: flex;
        height: 0.4rem !important;
        color: #fff;

        .vjs-menu {
          width: 1.5rem;
          // left: -56px;
          left: -0.4rem;
          // top: 40px;
          top: 0.34rem;
          .vjs-texttrack-settings {
            font-size: 0.14rem !important;
          }
        }
        // &:hover {
        // background: #63c155;
        // color: #7dd473 !important;

        // border-radius: 50px;
        // // background-image: url("../../assets/new_ui/btn_bg.png");
        // // background-size: 100% 100%;
        // // background-repeat: no-repeat;
        // background-position: center;
        // }
        .vjs-icon-placeholder {
          // display: none;
          &:before {
            content: "字幕";
            font-size: 0.18rem;
            // color: #fff;
            display: inline-block;
            // line-height: 48px;
            font-family: DFPFangYuanW7;

            // width: 53px;
            // height: 32px;
            // &:hover {
            //   background: linear-gradient(
            //     180deg,
            //     rgba(0, 0, 0, 0) 0%,
            //     rgba(0, 0, 0, 0.42) 100%
            //   );
            // }
          }
        }
      }
      .vjs-control.vjs-audio-button {
        // background-image: url("../../assets/new_ui/vjs-next.png");
        // background-repeat: no-repeat;
        // background-size: 50px 50px;
        // background-position: center;
        position: absolute;
        right: 2.3rem;
        top: 0.05rem;
        height: 0.4rem !important;

        padding: 0px 0.35rem;
        color: #fff;

        // .vjs-menu-button {
        //   color: #fff !important;
        // }
        // &:hover {
        //   color: #7dd473 !important;
        //   border-radius: 50px;
        // }

        .vjs-menu {
          left: 0.02rem;
          top: 0.34rem;
        }

        .vjs-icon-placeholder {
          &:before {
            content: "语言";
            font-size: 0.18rem;
            // color: #fff;
            display: inline-block;
            // line-height: 48px;
            font-family: DFPFangYuanW7;
          }
        }
      }

      .vjs-control.vjs-playback-rate {
        padding: 0px 0.35rem;
        position: absolute;
        right: 1.6rem;
        top: 0.05rem;
        // color: pink !important;
        // margin-left: calc(100% - 450px);
        margin-left: 0.3rem;
        font-family: "DFPFangYuanW7";
        font-size: 0.18rem;
        // height: 40px !important;

        // &:hover {
        //   color: #7dd473 !important;
        //   border-radius: 50px;
        // }
        .vjs-playback-rate-value {
          color: #fff;
          // top: 0.06rem;
          top: 0.02rem;
          font-size: 0.18rem;
          // color: #ffffff;
          // line-height: 38px;
          height: 100%;
          // &:hover {
          //   color: #7dd473 !important;
          // }
        }
        .vjs-menu {
          left: 0.02rem;
          top: 0.34rem;
        }
        .vjs-icon-placeholder {
        }
      }
      .vjs-volume-panel {
        position: absolute;
        right: 0.9rem;
        margin-left: 0.25rem;
        .vjs-mute-control {
          border-radius: 0.06rem;
          &.vjs-vol-0 {
            width: 0.46rem;

            background-image: url("../../assets/new_ui/vjs-mute.png");
            background-repeat: no-repeat;
            background-size: 0.5rem 0.5rem;
            // background-position: center;
            &:hover {
              filter: saturate(125%) brightness(108%);
            }
          }
          &.vjs-vol-1,
          &.vjs-vol-2,
          &.vjs-vol-3 {
            width: 0.46rem;
            background-image: url("../../assets/new_ui/vjs-vol.png");
            background-repeat: no-repeat;
            background-size: 0.5rem 0.5rem;
            // background-position: center;
            &:hover {
              filter: saturate(125%) brightness(108%);
            }
          }
          .vjs-icon-placeholder {
            display: none;
          }
        }
        .vjs-volume-control {
          width: 0.36rem;
          height: 1rem;
          border-radius: 0.06rem;
          bottom: 1.06rem !important;
          bottom: 0.98rem !important;
          .vjs-volume-bar {
            width: 0.04rem;
            height: 0.8rem;
            margin: 0.1rem auto;
            border-radius: 0.02rem;
            background: rgba(255, 255, 255, 0.5);
            .vjs-volume-level {
              width: 0.04rem;
              border-radius: 0.02rem;
              background: #69c984;
              &:before {
                font-size: 0.14rem;
                color: #ffffff;
              }
            }
          }
        }

        .vjs-volume-level {
          color: #69c984;
          &:before {
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .vjs-volume-vertical {
          bottom: 9em;
        }
        &.vjs-hover .vjs-volume-vertical {
          left: -0.38rem;
          // top: -95px;
        }
      }
      .vjs-fullscreen-control {
        position: absolute;
        right: 0.2rem;
        margin-left: 0.25rem;
        .vjs-icon-placeholder:before {
          content: "";
        }
        &[title="Fullscreen"],
        &[title="全屏"] {
          background-image: url("../../assets/new_ui/vjs-fullscreen.png");
          background-repeat: no-repeat;
          background-size: 0.5rem 0.5rem;
          // background-position: center;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
        }
        &[title="Non-Fullscreen"],
        &[title="退出全屏"] {
          background-image: url("../../assets/new_ui/vjs-fullscreen-exit.png");
          background-repeat: no-repeat;
          background-size: 0.5rem 0.5rem;
          // background-position: center;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
          .vjs-icon-placeholder {
            display: none;
          }
        }
      }
      .vjs-button > .vjs-icon-placeholder:before {
        font-size: 2.8em;
      }
      .vjs-button > .vjs-icon-placeholder:before {
        line-height: 0.4rem;
      }
      .vjs-slider {
        background-color: rgba(255, 255, 255, 0.3);
      }
      .vjs-load-progress div {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 0.04rem;
      }
    }

    .vjs-big-play-button {
      width: 0.62rem;
      height: 0.62rem;
      margin-top: -0.31rem;
      margin-left: -0.31rem;
      background-image: url("../../assets/icon-play.png");
      background-repeat: no-repeat;
      background-size: 0.62rem 0.62rem;
      background-position: center;
      border-radius: 0;
      border: 0 solid transparent;
      background-color: transparent;
      .vjs-icon-placeholder {
        display: none;
      }
    }
    .vjs-modal-dialog-content {
      font-size: 0.18rem;
      color: #ffffff;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0);
      padding-top: 1rem;
    }
  }
  &.video-loading {
    &:before {
      content: "";
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -0.25rem;
      margin-top: -0.25rem;
      background-image: url("../../assets/video-loading.png");
      background-repeat: no-repeat;
      background-size: 0.5rem 0.5rem;
      animation: spin 1s infinite linear;
    }
  }
  .img-collection {
    width: 0.4rem;
    height: 0.4rem;
    position: absolute;
    top: 0.5rem;
    right: 0.4rem;
    cursor: pointer;
    &:hover {
      filter: saturate(125%) brightness(108%);
    }
  }
}
.video-js {
  border-radius: 0.3rem;
  background-color: transparent;
  width: 100% !important;
  height: 100% !important;
}
.vjs-poster {
  border-radius: 0.3rem;
  // background: rgba(0, 0, 0, 0);
  background-color: transparent;
}
.vjs-error-display {
  border-radius: 0.3rem;
}
.video-js .vjs-control-bar {
  // bottom: 0.01rem;
  // border-radius: 30px;
}

.video-js .vjs-tech {
  position: absolute;
  top: -0.02rem;
  left: -0.03rem;
  width: 101%;
  height: 101%;
  background: #000;
  border-radius: 0.01rem;
}

.video-js.vjs-fullscreen:not(.vjs-ios-native-fs) {
  .vjs-control-bar {
    border-radius: 0 !important;
  }
}
</style>