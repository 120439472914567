/** 
 * @Desc: 首页内容
 */
<template>
  <div class="page-wrapper-box">
    <div class="picLeft">
      <pic-list :picDatas="picDatas" :activeTaskId="activeTaskId"></pic-list>
    </div>
    <!-- <div class="home-left"> -->
    <div class="picRight">
      <PicContent ref="PicContent" @openLogin="openLogin" :isShowBtn="isShowBtn" :imageData="imageData || {}"
        @getImg="getImg"></PicContent>
        <!-- <RecContent></RecContent> -->
    </div>
    <!-- 登录 注册 找回密码 -->
    <LoginRegisterPwdDialog :visible="loginDialogVisible" component="login" @handleClick="_closeLoginDialog" />
    <!-- </div> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import PicList from './PicList'
import { SetVideoStatistics } from "api/common";
import PicContent from './PicContent.vue'
import LoginRegisterPwdDialog from "components/login-register-pwd-dialog";
import { reqPainting, getImg, getProgress } from '@/api/resource.js'
// import RecContent from './RecContent.vue'
export default {
  components: {
    LoginRegisterPwdDialog,
    PicList,
    PicContent,
    // RecContent
  },
  inject: ["reload"],
  data() {
    return {
      loginDialogVisible: false,
      imageData: {}, //默认大图
      pageNum: 1,
      pageSize: 10,
      query: {
      },
      totalCount: 5,
      picDatas: [
      ],
      isShowBtn: false,
      stopCode: false,
      activeTaskId: '',
      requestNum: 1
    };
  },
  created() {
    if (this.isLogin) {
      this.getPainting()
    }
  },
  computed: {
    ...mapState(["accountInfo", "isLogin"])
  },
  watch: {
    deep: true,
    handler: {
      picDatas(newVal, oldVal) {
        console.log(newVal, oldVal)
      }

    }


  },
  methods: {
    //打开登录页面
    openLogin() {
      this.loginDialogVisible = true
    },
    /**
 * 页面reload
 * **/
    _relaod() {
      this.reload();
    },
    //获取历史绘画数据
    async getPainting() {
      let taskId = ''
      if(this.picDatas.length > 0){
         taskId = this.picDatas[this.picDatas.length-1].taskId
      }
      let { pageNum, pageSize } = this
      let query = {
        // pageNum,
        pageSize,
        pageSource: 1,
        taskId
      }
      let res = await reqPainting(query)
      if (res && res.data) {
        this.pageNum = res.data.pageNum;
        this.pageSize = res.data.pageSize;
        this.totalCount = res.data.totalCount;
        let picArr = res.data.datas;
        this.picDatas = [...this.picDatas, ...picArr]
        if(this.requestNum == 1){
          this.imageData = this.picDatas[0]
        }
        this.requestNum == this.requestNum++
        // console.log(this.pageNum, '---this.pageNum---');
        // if (this.pageNum > 1) {
        //   picArr = res.data.datas;
        //   this.picDatas = [...this.picDatas, ...picArr]
        // } else {
        //   this.picDatas = res.data.datas
        //   this.imageData = this.picDatas[0]
        // }
      }
      this.isShowBtn = this.picDatas.length == 0 ? false : true
      if (res && res.data.datas.length < 1) return
      // this.pageNum = this.pageNum + 1
    },
    //图片生成进度
    async getProgress(taskId) {
      let res = await getProgress({ taskId })
      let timer = setTimeout(() => {
        console.log(res)
        this.getProgress(taskId)
      }, 5000)
      if (res.data[0].status == 1 ||res.data[0].status == 3) {
        clearInterval(timer);
        for (let i = 0; i < res.data.length; i++) {
          this.imageData = res.data[i]
          if(i == 0) {
            this.$set(this.picDatas, 0, this.imageData)
          } else {
            this.picDatas.unshift(this.imageData);
          }
        }
        this.activeTaskId = res.data[res.data.length - 1].taskId
        this._changePicShow(res.data[res.data.length - 1].taskId)
        this.$refs.PicContent.submitFlag = false
        
      }
    },
    //获取图片的接口
    async getImg(parmas) {
      if (!this.stopCode) {
        let res = await getImg(parmas)
        if (res && res.code == 0) {
          this.picDatas.unshift(res.data)
          //this.$set(this.picDatas, 0, res.data)
          this.isShowBtn = this.picDatas.length == 0 ? false : true
          let taskId = res.data.taskId

          SetVideoStatistics({
            resourceId: taskId,
            level: 1,
            subject: 1,
            type: 'edit'
          });

          //高亮第一个
          this._changePicShow(taskId)
          this.activeTaskId = res.data.taskId
          //心跳请求
          this.getProgress(taskId)
          this.$refs.PicContent.getImgQuery.prompts = '';
        } else if (res && res.code == 13004) {
         this.openHTML(res.data)
        this.$refs.PicContent.submitFlag = false;
        } else if (res && res.code == 13007) {
        this.$refs.PicContent.submitFlag = false;
          this.stopCode = true;
          this.$message.warning('绘图次数已使用完毕，不可绘制图片')
        } else if (res && res.code == 13008) {
        this.$refs.PicContent.submitFlag = false;
          this.$message.warning('当前图片正在绘制中，请稍后再试...')
        } else {
        this.$refs.PicContent.submitFlag = false;
          this.$message.warning(res.message || '其他错误')
          this.picDatas.splice('0', 1)
          this.$nextTick(() => {
            this.$refs.PicContent.getImgQuery.prompts = '';
          })
        }
      } else {
        this.$message.warning('绘图次数已使用完毕，不可绘制图片')
        this.$refs.PicContent.submitFlag = false;
      }

    },
    //敏感词高亮
    openHTML(data) {
     let message  =   data.map(item=>{
         return `<strong> <i style="color:#448816">${item}</i> </strong>`
        })
        this.$message({
          type:'warning',
          dangerouslyUseHTMLString: true,
          message:`<i>敏感字符：</i>${message.join(',')}`
        });
      },
    /**
     * 关闭登录
     * **/
    _closeLoginDialog(type) {
      this.loginDialogVisible = false;
      if (type === "reload") {
        this.reload();
      }
    },
    /**
     * 点小图切换大图
     */
    _changePicShow(taskId) {
      let arrFindImgDate = this.picDatas.find(item => item.taskId == taskId)
      // this.imageData = arrFindImgDate[0] || {}
      this.$nextTick(() => {
        this.imageData = arrFindImgDate || {}
        this.activeTaskId = taskId
      })
    },
    _deleteImageDate(taskId) {
      let numFindImgDate = this.picDatas.findIndex(item => item.taskId == taskId)
      if (numFindImgDate == this.picDatas.length - 1) {
        this.$delete(this.picDatas, numFindImgDate)
        if(this.picDatas.length > 0){
          let changeTaskId = this.picDatas[this.picDatas.length - 1].taskId
          this.activeTaskId = changeTaskId
          this._changePicShow(changeTaskId)
        }
      } else if (numFindImgDate != -1) {
        let changeTaskId = this.picDatas[numFindImgDate + 1].taskId
        // this.activeTaskId = changeTaskId
        this._changePicShow(changeTaskId)
        this.$delete(this.picDatas, numFindImgDate)
      }
      this.isShowBtn = this.picDatas.length == 0 ? false : true
    }
  },
};
</script>
<style lang="less">
.el-message-box .el-message-box__title {
  font-size: .3rem;
  color:#448816;
  text-align: center;
  font-family: 'DFPFangYuanW7';  //重命名字体名
}
.page-wrapper-box {
  font-size: 0.16rem;
  width: 100%;
  height: 100%;
  display: flex;

  // padding: 3vh;
  .picLeft {
    width: 20%;
    height: 100%;
    margin-left: 2.5vw;
    border-radius: 30px;
    border: 0.03rem solid #ade787;
    background-color: rgba(255, 255, 255, 0.7);
    padding-bottom: 10px;
    overflow: hidden;
  }

  .picRight {
    padding: 0 2.5vw;
    width: 80%;
    height: 100%;
  }

  // .picBg {
  //   flex-grow: 1;
  //   width: 100%;
  //   background-image: url("../../assets/icon-select-right.png");
  // }
}
</style>
