<template>
  <div class="picListContainer">
    <div class="picLog">
      <div class="picLog-title">绘画记录</div>
      <!-- <img src="../../assets/paintingFailed.jpg" alt=""/> -->
    </div>
    <div class="picScrollContainer">
      <div class="tipMessage" v-if="!isLogin || picDatas.length == 0">
        <span class="img-desc desc-ide"></span>
        <span>您还没有绘画记录</span>
      </div>
      <div id="picListScrollContainer" @scroll="scrollLoadMore($event)" v-else>
        <div class="img-pic-list" v-for="(item, index) in picDatas" :key="index" @click="_clickChange(item, index)"
          :ref="'scrollDom' + item.taskId">
          <div :class="(item.taskId == defaultTaskId || index == activeIndex) ? 'active' : ''">
            <div v-if="item.status == 0" class="paintingFailText paintingFailText0"></div>
            <div v-if="item.status == 1" class=" paintingFailText">
              <el-image class="img-pic" :src="item.status == 1 ? item.thumbnailUrl : ''" alt="加载图片失败" fit="cover" ></el-image>
            </div>
            <div v-if="item.status == 3" class="paintingFailText paintingFailText3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ['picDatas', 'activeTaskId'],
  data() {
    return {
      defaultImg: '../../assets/personal_ip.png',
      activeIndex: 0,
      thumbnailList: [],
      defaultTaskId: 0,
      isInView: false,
      timer: null
    }
  },
  computed: {
    ...mapState(["isLogin", "isLodaingNew"])
  },
  watch:{
    activeTaskId(newVal, oldVal){
      if(newVal != oldVal){
        this.defaultTaskId = newVal
        this.activeIndex = this.picDatas.findIndex(item => item.taskId == newVal) || 0
        // console.log('n---', defaultTaskId, 'o---', activeIndex);
      }
    },
    activeIndex(newVal, oldVal){
      if(newVal == 0){
        $('#picListScrollContainer').animate({scrollTop: 0}, "slow")
        // console.log('------------------');
        // this.defaultTaskId = newVal
        // this.activeIndex = this.picDatas.find(item => item.taskId == newVal)
        // // console.log('n---', defaultTaskId, 'o---', activeIndex);
        // console.log(newVal, $('#picListScrollContainer'), '----picListScrollContainer---');
      }
    }
  },
  mounted() { },
  methods: {
    /**
     * 点击切换调用父组件
     */
    _clickChange(objChage, indexOfPic) {
      this.activeIndex = indexOfPic
      let taskId = objChage.taskId
      this.defaultTaskId = objChage.taskId
      this.$parent._changePicShow(taskId)
    },
    /**
     * 滚动加载更多
     */
    scrollLoadMore(event) {
      const scrollTop = event.target.scrollTop;
      const delta = scrollTop - this.lastScrollTop;
      this.lastScrollTop = scrollTop;
      if (delta > 0) {
        let taskId = this.picDatas[this.picDatas.length - 1].taskId
        let objElement = this.$refs['scrollDom' + taskId][0]
        // let objElement = document.getElementById('#scrollDom' + taskId);
        // console.log(objElement);
        //获取元素是否在可视区域
        let rect = objElement.getBoundingClientRect();
        // //判断位置
        let isInView = rect.top + rect.height / 2 <=
          (window.innerHeight || document.documentElement.clientHeight) && rect.bottom > 0;
        // console.log(isInView);
        if (isInView) {
          if (this.timer !== null) clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.$parent.getPainting()
          }, 200)
        } else {
          this.isInView = false
        }
      }
    }
  },
}
</script>
<style lang="less" scoped>
.picListContainer {
  height: 100%;
  // min-height: max(768px, 100%);
  // background: red;

  .picLog {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    // border-radius: .3rem .3rem 0 0;
    background-color: #ade787;

    .picLog-title {
      font-size: 1vw;
      font-weight: bold;
      color: #448816;
    }
  }

  .picScrollContainer {
    height: 90%;
    width: 100%;
    margin-top: .1rem;
    // padding: 0 .05rem .3rem .1rem;

    .tipMessage {
      width: 100%;
      height: 100%;
      font-size: .2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #448816;

      .img-desc {
        width: 3vw;
        height: 3vw;
        background-image: url("../../assets/new_ui/icon-info1.png");
        background-repeat: no-repeat;
        background-size: 100%;
        margin-bottom: 1.5vw;
      }
    }

    #picListScrollContainer {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      text-align: center;
      padding-top: 10px;

      .img-pic-list {
        display: inline-block;
        width: 14.5vw;
        height: 14.5vw;
        line-height: 14.5vw;
        margin-bottom: 10px;
        vertical-align: middle;

        .img-pic {
          width: 100%;
          height: 100%;
          border-radius: 20px;
          border: 1px solid #ade787;
          overflow: hidden;

        }

        .active {
          .paintingFailText{
            box-shadow: 0 0 0 5px #448816;
          border-radius: 20px;
          }
          .paintingFailText0,.paintingFailText3{
            border: none;
          }
        }

        .paintingFailText {
          width: 14.5vw;
          height: 14.5vw;
          position: relative;
          color:#448816;
        }

        .paintingFailText3 {
          background: url('../../assets/ui_ai-fe/绘制失败.jpg') no-repeat;
          background-size: 50%;
          background-position: center 30%;
          overflow: hidden;
          border: 3px solid #ade787;
          border-radius: 20px;

          &::after {
            display: inline-block;
            width: 100%;
            text-align: center;
            content: '绘图失败';
            position: absolute;
            bottom: -3vw;
            left: 0;
          }
        }

        .paintingFailText1 {
          background: url('../../assets/ui_ai-fe/绘制失败.jpg') no-repeat;
          background-size: 50%;
          background-position: center 30%;

          &::after {
            display: inline-block;
            width: 100%;
            text-align: center;
            content: '加载图片失败';
            position: absolute;
            bottom: -3vw;
            left: 0;
          }
        }

        .paintingFailText0 {
          background: url('../../assets/ui_ai-fe/绘制中.jpg') no-repeat;
          background-size: 50%;
          background-position: center 30%;
          overflow: hidden;
          border: 3px solid #ade787;
          border-radius: 20px;

          &::after {
            display: inline-block;
            width: 100%;
            text-align: center;
            content: '加载中...';
            position: absolute;
            bottom: -3vw;
            left: 0;
          }
        }
      }
    }

    #picListScrollContainer::-webkit-scrollbar {
      display: block;
      width: 5px;
    }

    // 伪 ::scrollbar-thumb 元素设置

    #picListScrollContainer::-webkit-scrollbar-thumb {
      display: block;
      border-radius: 8px;
      background-color: #448816;
    }

    // 伪 ::scrollbar-track 元素设置

    // #picListScrollContainer::-webkit-scrollbar-track {
    //   display: block;
    //   border-radius: 8px;
    //   background-color: #cdcdcd;
    //   // border: 1px solid #000000;
    // }
  }
}
</style>
