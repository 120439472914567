/** 
 * @Desc: 播放页面
 */
<template>
  <div class="play-page-wrapper" v-loading="loading">
    <div class="plye-page-box">
      <img class="play_shade" src="../../assets/new_ui/play_shade.png" alt="">
      <div class="play-box">
        <div v-show="!loading" class="play-left-box">
          <video-js
            v-if="isLogin || (videoObj && videoObj.isTrailer)"
            :videoObj="videoObj"
            @handlerCollection="_collection"
            @handlerNext="_playNext"
          />
          <div v-else-if="videoObj" @click.stop class="not-login-mask">
            <div class="not-login-box">
              <img class="no_login_img" src="../../assets/new_ui/no_login_img.png" alt="">
              <div>
                <p class="p1">登录注册后观看完整视频</p>
                <p class="p2">登录注册后你将有权限查看平台所有视频资源</p>
                <p class="p3 login-btn" @click="_clickLogin('register')">立即注册</p>
                <p class="p4">
                  已有账号？
                  <span @click="_clickLogin('login')">登录观看</span>
                </p>
              </div>
            </div>
          </div>
          <img
            v-if="!isLogin && videoObj && !videoObj.isTrailer"
            :src="videoObj && videoObj.coverUrl"
            alt=""
            class="not-login-img-cover"
          >
        </div>
        <div v-show="!loading" class="play-right-box">
          <div class="top-title">
            <span v-if="dataObj" class="packing-title">{{dataObj.resourcePackingTitle}}</span>
            <span v-if="dataList && dataList.length" class="play-count">
              <span>（</span>
              <span class="color-green">{{selectIndex + 1}}</span>
              <span>/</span>
              <span style="fontSize: 0.14rem">{{dataList.length}}</span>
              <span>）</span>
            </span>
            <template v-if="dataObj">
              <img
                v-if="!isLogin || !dataObj.isCollected"
                class="img-collection"
                src="../../assets/new_ui/collection.png"
                alt=""
                title="收藏"
                @click.stop="_collection()"
              >
              <img
                v-else
                class="img-collection"
                src="../../assets/new_ui/collection-cancel.png"
                alt=""
                title="取消收藏"
                @click.stop="_collection()"
              >
            </template>
          </div>
          <div class="list-box">
            <div class="arrow-container-prev cursor" @click.stop="_listPosition('prev')">
              <img class="img-arrow-top" src="../../assets/icon-arrow-top-1.png" alt="">
            </div>
            <div class="arrow-container">
              <div id="contentList" class="content-list">
                <div
                  :id="'resource_' + item.resourceId"
                  :class="{
                'resource-item-2': true,
                'resource-item-selected': packingHistory && packingHistory[resourcePackingId] === item.resourceId
              }"
                  :title="item.title"
                  v-for="(item, index) in dataList"
                  :key="index"
                  @click="_play(item)"
                >
                  <div class="resource-item-2-inner">
                    <div class="img-box">
                      <el-image :src="item.coverUrl" alt="">
                        <div slot="placeholder" class="image-slot">
                          <img
                            class="image-slot-placeholder"
                            src="../../assets/pic-loading-failed.png"
                            alt=""
                          >
                        </div>
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                      <div class="bottom-mask">
                        <span class="resource-title no-wrap">{{item.title}}</span>
                      </div>
                    </div>
                    <span v-if="!isLogin && item.isTrailer" class="is-trailer">试看</span>
                    <span
                      class="resource-duration"
                      v-if="item.duration"
                    >{{item.duration | tranformTime}}</span>
                  </div>
                  <div class="select-bg"></div>
                </div>
              </div>
            </div>
            <div class="arrow-container-next cursor" @click.stop="_listPosition('next')">
              <img class="img-arrow-bottom" src="../../assets/icon-arrow-bottom-1.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录 注册 找回密码 -->
    <LoginRegisterPwdDialog
      :visible="loginDialogVisible"
      :component="comType"
      @handleClick="_closeLoginDialog"
    />
  </div>
</template>
<script>
import VideoJs from "components/video-js";
import LoginRegisterPwdDialog from "components/login-register-pwd-dialog";
import { mapState } from "vuex";
import { reqCollectStatus, reqAddHistory, LocalHistory } from "api/common";
import { reqGetResourceList, reqGetVideo } from "api/resource";
import {
  formatDateTime,
  localStorageUtil,
  debounce,
  GetPageType
} from "utils/util";
export default {
  components: {
    VideoJs,
    LoginRegisterPwdDialog
  },
  data() {
    return {
      loading: true,
      resourcePackingId: null,
      resourceId: null,
      dataObj: null,
      dataList: [],
      pageType: null,
      scrollLoading: false,
      pageTotal: 0,
      pageSize: 12,
      pageNum: 1,
      selectIndex: null,
      resourcePackingTitle: "",
      playReord: null,

      videoObj: null,
      videoBox: null,
      packingHistory: null,
      showPrev: false,
      showNext: false,
      loginDialogVisible: false,
      comType: "login"
    };
  },
  watch: {
    showPrev(val) {
      $(".arrow-container-prev").css({
        top: val ? "-0.14rem" : "-0.7rem"
      });
    },
    showNext(val) {
      console.log(val, "list-box showNext");
      $(".arrow-container-next").css({
        bottom: val ? "-0.14rem" : "-0.7rem"
      });
    }
  },
  computed: {
    ...mapState(["accountInfo", "isLogin"])
  },
  methods: {
    _clickLogin(type) {
      this.comType = type;
      this.loginDialogVisible = false;
      setTimeout(() => {
        this.loginDialogVisible = true;
      }, 100);
    },

    /**
     * 关闭登录
     * **/
    _closeLoginDialog(type) {
      this.loginDialogVisible = false;
    },
    /**
     * 点击右侧列表播放
     * **/
    _play(item) {
      this._setTopicHistory("select", item);
    },
    /**
     * 播放器点击下一个时播放
     * **/
    _playNext() {
      let i = this.dataList.findIndex(
        ele => ele.resourceId === this.videoObj.resourceId
      );
      if (i !== -1 && i < this.dataList.length - 1) {
        this._setTopicHistory("select", this.dataList[i + 1]);
      }
    },

    /**
     * 数据获取
     * **/
    _getData() {
      let obj = {
        resourcePackingId: this.resourcePackingId,
        typeId: this.pageType
      };
      if (this.pageNum === 1) {
        this.loading = true;
      }
      return reqGetResourceList(obj)
        .then(res => {
          this.loading = false;
          this.scrollLoading = false;
          if (res.code === 0) {
            this.dataObj = res.data;
            this.pageTotal = res.data.resources.length;
            let _list = [...res.data.resources];
            for (let item of _list) {
              item.pageType = this.pageType;
            }
            this.dataList = _list;
            this._setTopicHistory("init");
            this._getBoxWH("init");
          } else {
            this.$message({
              type: "error",
              message: "资源获取失败",
              duration: 2000
            });
            console.log(res);
          }
        })
        .catch(err => {
          this.loading = false;
          this.scrollLoading = false;
          console.log("资源获取失败", err);
        });
    },

    /**
     * 获取播放资源
     * **/
    _getVideo(data) {
      let obj = {
        resourceId: data.resourceId
      };
      this.videoObj = null;
      return reqGetVideo(obj)
        .then(res => {
          let i = this.dataList.findIndex(
            ele => ele.resourceId === data.resourceId
          );
          this.videoObj = Object.assign(
            {
              videoUrl: res,
              lastOne: i === this.dataList.length - 1 ? true : false,
              resourcePackingTitle: this.dataObj.resourcePackingTitle
            },
            data,
            this.videoBox
          );
          console.log(this.videoObj, "videoObj");
        })
        .catch(err => {
          this.videoObj = { error: "资源获取失败" };
          console.log(err);
        });
    },
    /**
     * 记录上次播放资源
     * **/
    _setTopicHistory(type, item) {
      let _item = Object.assign({}, item);
      let phone =
        this.accountInfo && this.accountInfo.phone
          ? this.accountInfo.phone + "_"
          : "";
      let packingHistory = localStorageUtil.getItem(phone + "packingHistory");
      if (!packingHistory) {
        packingHistory = {};
      }
      if (type === "init") {
        let i = this.dataList.findIndex(
          ele => ele.resourceId === packingHistory[this.resourcePackingId]
        );
        i = i === -1 ? 0 : i;
        _item = this.dataList[i];
        this.selectIndex = i;
        if (i >= 3) {
          setTimeout(() => {
            this._listPosition(
              "init",
              Object.assign({ selectIndex: this.selectIndex }, _item)
            );
          }, 200);
        }
      } else {
        this.selectIndex = this.dataList.findIndex(
          ele => ele.resourceId === _item.resourceId
        );
        if (!this.isLogin) {
          LocalHistory("play-list", _item);
        }
      }
      if (type === "select") {
        this._listPosition(
          "select",
          Object.assign({ selectIndex: this.selectIndex }, _item)
        );
      }
      packingHistory[this.resourcePackingId] = _item.resourceId;
      this.packingHistory = packingHistory;

      let _playReord = {};
      _playReord[this.resourcePackingId] = _item.resourceId;
      localStorageUtil.setItem("playReord", _playReord);
      if (!this.isLogin && !_item.isTrailer) {
        this.videoObj = Object.assign(
          { resourcePackingTitle: this.dataObj.resourcePackingTitle },
          _item,
          this.videoBox
        );
        return;
      }
      if (this.isLogin || (!this.isLogin && _item.isTrailer)) {
        localStorageUtil.setItem(phone + "packingHistory", packingHistory);
      }
      this._getVideo(_item);
      if (this.isLogin) {
        this._history(_item);
      }
    },
    /**
     * 收藏 取消收藏
     * **/
    _collection: debounce(function() {
      if (!this.isLogin) {
        this._clickLogin("login");
        return;
      }
      let obj = {
        resourcePackingId: this.dataObj.resourcePackingId
      };
      return reqCollectStatus(obj)
        .then(res => {
          if (res.code === 0 || res.code === 200) {
            this.dataObj = Object.assign({}, this.dataObj, {
              isCollected: !this.dataObj.isCollected
            });
            this.$message({
              type: "success",
              message: this.dataObj.isCollected ? "收藏成功~" : "已取消收藏~",
              duration: 2000
            });
          } else {
            this.$message({
              type: "error",
              message: this.dataObj.isCollected ? "取消收藏失败~" : "收藏失败~",
              duration: 2000
            });
          }
        })
        .catch(err => {
          console.log("收藏/取消收藏失败", err);
        });
    }),
    /**
     * 新增历史记录
     * **/
    _history(item) {
      return;
      let obj = {
        resourceId: item.resourceId
      };
      return reqAddHistory(obj)
        .then(res => {
          if (res.code === 0) {
          } else {
            console.log("新增历史记录失败", res);
            // this.$message({
            //   type: "error",
            //   message: this.resourceInfo.isCollected ? "取消收藏失败" : "收藏失败",
            //   duration: 2000
            // });
          }
        })
        .catch(err => {
          console.log("新增历史记录失败", err);
        });
    },

    /**
     * 右侧列表资源scrollTop修改 定位
     * **/
    _listPosition(type, item) {
      let ele_h = $(".arrow-container").innerHeight();
      let list_h = $("#contentList").height(); //
      let scroll_t = $(".arrow-container").scrollTop(); //

      if (type === "prev") {
        $(".arrow-container").animate({
          scrollTop: scroll_t > ele_h ? scroll_t - ele_h + "px" : 0
        });
      } else if (type === "next") {
        $(".arrow-container").animate({
          scrollTop:
            scroll_t + ele_h > list_h ? list_h + "px" : scroll_t + ele_h + "px"
        });
      } else if (type === "select") {
        if (list_h - 30 <= ele_h) {
          return;
        }
        let select_ele = $(`#resource_${item.resourceId}`);
        let select_ele_h = select_ele.height();
        let select_ele_t = select_ele.position().top;
        let _scroll = 0;
        if (select_ele_t < 40) {
          _scroll = scroll_t + select_ele_t - select_ele_h;
          $(".arrow-container").animate({
            scrollTop: _scroll <= 0 ? 0 : _scroll + "px"
          });
        } else if (ele_h - select_ele_t - select_ele_h < 120) {
          _scroll = scroll_t + select_ele_h + 16;
          if (_scroll > list_h - ele_h + 16) {
            _scroll = list_h - ele_h + 16;
          }
          $(".arrow-container").animate({
            scrollTop: _scroll + "px"
          });
        }
      } else if (type === "init") {
        let { resourceId, selectIndex } = item;
        let select_ele = $(`#resource_${resourceId}`);
        let select_ele_h = select_ele.height();
        let _over_t = (select_ele_h + 16) * selectIndex;
        let _scroll = _over_t - ele_h / 2 + select_ele_h / 2;
        if (_scroll > list_h - ele_h + 16) {
          _scroll = list_h - ele_h + 16;
        }
        $(".arrow-container").animate({
          scrollTop: _scroll + "px"
        });
      }
    },

    /**
     * 根据宽度确定播放器高度
     * **/
    _getBoxWH(type) {
      this.$nextTick(() => {
        if ($("#app").width < 1200) {
          return;
        }
        if (type || type === "init") {
          let _w1 = $("#contentList").width() * 0.75;
          let _h1 = (_w1 * 9) / 16;
          $("#contentList .resource-item-2").each(function() {
            $(this).css({
              width: _w1 + "px",
              height: _h1 + "px"
            });
          });
        }
        let _w = $(".play-left-box").width();
        let _h = $(".play-left-box").height();
        // let _h = (_w * 9) / 16;
        // $(".play-page-wrapper").height(_h + "px");
        this.videoBox = {
          width: _w + "px",
          height: _h + "px"
        };
        if ($("#videoJs")[0]) {
          $("#videoJs").css({
            width: _w + "px",
            height: _h + "px"
          });
        }
      });
    },

    /**
     * 监听scroll事件
     * **/
    _handleWinScroll(e) {
      let ele_h = $(".arrow-container").innerHeight(); //
      let list_h = $("#contentList").height(); //
      let scroll_t = $(".arrow-container").scrollTop(); //
      if (list_h < ele_h || list_h - ele_h < 20) {
        this.showPrev = false;
        this.showNext = false;
      } else {
        // console.log(scroll_t, ele_h + scroll_t < list_h - 20);
        if (scroll_t > 300) {
          this.showPrev = true;
        } else {
          this.showPrev = false;
        }
        if (ele_h + scroll_t < list_h - 20) {
          this.showNext = true;
        } else {
          this.showNext = false;
        }
      }
    },
    /**
     * 监听浏览器tab切换关闭最小化
     * **/
    _visibilitychange() {
      let state = document.visibilityState;
      let _progress = $(".vjs-slider-bar").eq(0);
      let _video = document.querySelector("#videoJs video");
      if (state === "visible" && _progress && _video) {
        let _currentTime = _video.currentTime;
        let _duration = _video.duration;
        if (_currentTime == _duration) {
          setTimeout(() => {
            _progress.css({ width: "100%" });
          }, 500);
        }
      }
    },
    _pageInit() {
      let { resourcePackingId, resourceId, type } = this.$route.query;
      this.pageType = GetPageType(this.$route);
      this.resourcePackingId = resourcePackingId;
      this.resourceId = resourceId;
      this._getData();
    }
  },
  created() {
    this._getBoxWH();
    this._pageInit();
    localStorageUtil.removeItem("vjs_rate");
  },
  mounted() {
    this.$nextTick(() => {
      $("#app")[0].scrollIntoView({ behavior: "instant" });
      window.addEventListener("resize", this._getBoxWH);
      document
        .querySelector(".arrow-container")
        .addEventListener("scroll", this._handleWinScroll);
    });
    if (document.hidden !== undefined) {
      document.addEventListener("visibilitychange", this._visibilitychange);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this._getBoxWH);
    document.removeEventListener("visibilitychange", this._visibilitychange);
    document.querySelector(".arrow-container") &&
      document
        .querySelector(".arrow-container")
        .removeEventListener("scroll", this._handleWinScroll);
  }
};
</script>
<style lang="less">
.play-page-wrapper {
  width: 16.44rem;
  height: 7.61rem;
  margin: 0 auto;
  border-radius: 0.4rem;
  position: relative;

  .plye-page-box {
    width: 12rem;
    // width: calc(155.5vh);
    width: 100%;
    height: 100%;

    background: #ade787;
    border-radius: 0.4rem;
    position: absolute;
    // margin-top: 125px;
    margin-top: 0.9rem;
  }

  .play-box {
    width: 100%;
    height: 100%;
    background: #ade787;
    border-radius: 0.4rem;
    position: relative;
    .play-left-box {
      display: inline-block;
      vertical-align: top;
      // width: 77.8%;
      width: 13.12rem;
      height: 100%;

      background: rgba(0, 0, 0, 1);
      background: #fff;
      overflow: hidden;
      position: relative;
      border-radius: 0.4rem;
      padding: 0.24rem;
      // padding-top: 0.4rem;

      #videoBox {
        // width: 100%;
        // height: 100%;
      }
      .not-login-mask {
        width: 100%;
        height: 100%;
        backdrop-filter: blur(15px);
        background-color: rgba(4, 24, 4, 0.7);
        animation: self_fadeIn_1 0.2s linear forwards;
        position: relative;
        left: 0px;
        top: 0;
        z-index: 4;
        border-radius: 0.3rem;
        display: flex;
        img {
          border-radius: 0.3rem;
        }

        .not-login-box {
          display: flex;
          // width: 3.6rem;
          width: 6rem;
          // height: 230px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          opacity: 0;
          animation: self_fadeIn_1 0.3s linear forwards;

          .no_login_img {
            // width: 65%;
            // height: 70%;
            border-radius: 0px;
            // margin-right: 0.5rem;
            width: 100%;
          }

          p {
            color: #ffffff;
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
          .p1 {
            line-height: 0.25rem;
            height: 0.25rem;
            font-size: 0.18rem;
            font-weight: bold;
            margin-top: 0.4rem;
            margin-left: 0.3rem;
          }
          .p2 {
            margin-top: 0.15rem;
            line-height: 0.1rem;
            height: 0.22rem;
            font-size: 0.16rem;
            width: 3.3rem;
            margin-left: 0.3rem;
          }
          .p3 {
            margin: 0 auto;
            margin-top: 0.42rem;
            margin-top: 0.2rem;

            color: #fff;
            margin-left: 0.95rem;
          }
          .p4 {
            margin-top: 0.24rem;
            line-height: 0.22rem;
            height: 0.22rem;
            font-size: 0.16rem;
            color: #fff;
            margin-left: 0.3rem;
            span {
              color: #fcae2c;
              font-weight: 700;
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .login-btn {
        width: 2rem;
        height: 0.46rem;
        line-height: 0.46rem;
        text-align: center;
        font-size: 0.16rem;
        border-radius: 0.1rem;
      }
      .not-login-img-cover {
        width: 12.6rem;
        height: 7.13rem;
        position: absolute;
        top: 0.25rem;
        border-radius: 0.36rem;
      }
    }
    .play-right-box {
      display: inline-block;
      vertical-align: top;
      width: 22.2%;
      width: 3.3rem;
      height: 100%;
      background: #ade787;
      position: relative;
      padding-bottom: 0.1rem;
      overflow: hidden;
      border-radius: 0.3rem;
      position: relative;
      z-index: 10;
      .top-title {
        width: 100%;
        // height: 60px;
        min-height: 0.76rem;
        line-height: 0.2rem;
        // padding-left: 8%;
        padding-left: 0.33rem;
        padding-right: 0.2rem;
        color: #696969;
        position: relative;
        overflow-x: auto;
        .packing-title {
          display: inline-block;

          // color: #00827e;
          color: #1c8816;
          font-size: 0.16rem;
          // font-size: 18px;
          // font-weight: bold;
        }
        .play-count {
          color: #1c8816;
          font-size: 0.16rem;

          .color-green {
            font-size: 0.18rem;
            color: #ff9346;
          }
        }
        .img-collection {
          width: 0.3rem;
          height: 0.3rem;
          position: absolute;
          top: 50%;
          margin-top: -0.13rem;
          right: 0.33rem;
          cursor: pointer;
          &:hover {
            filter: saturate(125%) brightness(108%);
          }
        }
      }
      @media (max-width: 1600px) {
        .packing-title {
          font-size: 16px;
        }
        .play-count {
          font-size: 16px;
          .color-green {
            font-size: 14px;
          }
        }
        .img-collection {
          right: 20px;
        }
      }
      .list-box {
        height: calc(100% - 0.6rem);
        overflow: hidden;
        position: relative;
        // padding-top: 10px;

        .arrow-container-prev {
          position: absolute;
          top: -0.7rem;
          left: 4%;
          width: 92%;
          height: 0.6rem;

          background: #ade787;
          box-shadow: 0px 0.19rem 0.18rem -0.18rem #022b0e;
          z-index: 3;
          transition: top 0.3s;
          &:hover {
            top: 0 !important;
          }
        }
        .arrow-container-next {
          position: absolute;
          // bottom: -70px;
          bottom: -0.7rem;
          left: 4%;
          width: 92%;
          height: 0.6rem;
          background: #ade787;
          box-shadow: 0 -0.19rem 0.18rem -0.18rem #022b0e;
          z-index: 3;
          transition: bottom 0.3s;
          &:hover {
            bottom: 0 !important;
          }
        }
        .img-arrow-top,
        .img-arrow-bottom {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 0.4rem;
          &.img-arrow-top {
            bottom: 0.115rem;
          }
          &.img-arrow-bottom {
            // top: 10.5px;
            top: 0.105rem;
          }
        }
        .arrow-container {
          width: 100%;
          height: 100%;
          padding-top: 0.1rem;
          overflow: auto;
          .content-list {
            text-align: center;

            .resource-item-2 {
              display: inline-block;
              margin-bottom: 0.16rem;
              cursor: pointer;
              position: relative;
              .resource-item-2-inner {
                width: 100%;
                height: 100%;
                position: relative;
                background: rgba(255, 255, 255, 0.45);
                border-radius: 0.244rem;
                border: 0.08rem solid #d9fdc2;
              }
              .img-box {
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 0.166rem;
                position: relative;
                .el-image__inner {
                  transition: all 0.5s;

                  &:hover {
                    transform: scale(1.1);
                  }
                }
                .el-image {
                  width: 100%;
                  height: 100%;
                  .image-slot {
                    width: 100%;
                    height: 100%;
                    .image-slot-placeholder {
                      font-size: 0px;
                      text-align: center;
                      display: inline-block;
                      vertical-align: top;
                      width: 100%;
                      height: 100%;
                    }
                    .el-icon-picture-outline {
                      font-size: 0px;
                      text-align: center;
                      display: inline-block;
                      vertical-align: top;
                      width: 100%;
                      height: 100%;
                      background-image: url("../../assets/pic-loading-failed.png");
                    }
                  }
                }
                .bottom-mask {
                  width: 100%;
                  height: 0.44rem;
                  background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.65) 100%
                  );
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  .resource-title {
                    display: inline-block;
                    width: calc(100% - 40px);
                    height: 0.2rem;
                    line-height: 0.2rem;
                    text-align: left;
                    font-size: 0.17rem;
                    // font-family: Avenir, Helvetica, Arial, sans-serif;
                    color: #ffffff;
                    position: absolute;
                    left: 0.14rem;
                    bottom: 0.12rem;
                  }
                }
              }

              .is-trailer {
                display: inline-block;
                width: 0.58rem;
                height: 0.5rem;
                line-height: 0.31rem;
                background-image: url("../../assets/new_ui/trailer.png");
                background-repeat: no-repeat;
                background-size: 100%;
                position: absolute;
                left: -0.13rem;
                top: 0.08rem;
                font-size: 0.14rem;
                // font-weight: bold;
                // font-family: Avenir, Helvetica, Arial, sans-serif;
                color: #ffffff;
              }

              .resource-duration {
                width: 0.54rem;
                // padding: 0 6px;
                border-radius: 0.1446rem;
                height: 0.24rem;
                line-height: 0.24rem;
                font-size: 0.12rem;
                // font-family: Avenir, Helvetica, Arial, sans-serif;
                color: #ffffff;
                background-color: rgba(0, 0, 0, 0.6);
                position: absolute;
                right: 0.12rem;
                top: 0.12rem;
              }
              &:not(.resource-item-selected):hover {
                .resource-item-2-inner {
                  // border-color: #FFE68B;
                }
              }
              &.resource-item-selected {
                .resource-item-2-inner {
                  border-color: #fff;
                  position: relative;
                  z-index: 2;
                }
                .select-bg {
                  width: calc(100% - 4px);
                  height: 80%;
                  background: #1a9928;
                  border-radius: 0.24rem;
                  z-index: 1;
                  position: absolute;
                  left: 0.02rem;
                  bottom: -0.08rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .play_shade {
    width: 17.68rem;
    position: absolute;
    // bottom: -37px;
    // left: -61px;

    bottom: -0.37rem;
    left: -0.61rem;
  }

  .arrow-container::-webkit-scrollbar {
    display: block !important;
    // color: #00827e !important;
    // background-color: #86c35f !important;
  }

  // height: calc(100vh - 100px);
}

.arrow-container::-webkit-scrollbar-thumb {
  background-color: #86c35f !important;
}

// 16 : 10视频位置
.h16-10 {
  .play-page-wrapper .plye-page-box {
    margin-top: 1.6rem;
  }
}
</style>