<template>
  <div class="picRightContainer" :class="isLogin ? '' : 'picBg'">
    <!-- -->
    <div class="picMainContainer">
      <div v-show="!isLogin" class="tree">
        <img src="../../assets/new_ui/tree_980.png" alt="" />
      </div>
      <div v-show="isLogin" :class="isShowBtn ? 'picMain' : ''">
        <div v-if="!isShowBtn" class="tree">
          <img src="../../assets/new_ui/tree_980.png" alt="" />
        </div>
        <div v-else class="picMainItem">
          <div class="picContainer">
            <!-- <div class="img-pic" v-show="imageData && imageData.status == 1">
              <el-image v-for="(item, index) in imageData.imageUrls" :key="index" :src="item.paintingRecordUrl"
                :preview-src-list="imageData.imageUrls.map(
                  (urlItem) => urlItem.paintingRecordUrl
                )
                  " fit="fit" alt="" @click="clickImage"></el-image>
            </div> -->

            <div class="img-pic" v-if="imageData && imageData.status == 1 && imageData.imageUrls.length == 1">
              <el-image style="width:100%;height:100%" v-for="(item, index) in imageData.imageUrls" :key="index" :src="item.paintingRecordUrl"
                :preview-src-list="imageData.imageUrls.map(
                  (urlItem) => urlItem.paintingRecordUrl
                )
                  " fit="fit" alt="" @click="clickImage"></el-image>
            </div>
            <div class="img-pic" v-else-if="imageData && imageData.status == 1">
               <el-image v-for="(item, index) in imageData.imageUrls" :key="index" :src="item.paintingRecordUrl"
                :preview-src-list="imageData.imageUrls.map(
                  (urlItem) => urlItem.paintingRecordUrl
                )
                  " fit="fit" alt="" @click="clickImage"></el-image>
            </div>

            <div class="picMainPic" v-show="imageData && imageData.status == 0">
              <div class="picStauts picLoading"></div>
            </div>
            <div class="picMainPic" v-show="imageData && imageData.status == 3">
              <div class="picStauts picError"></div>
            </div>
          </div>
          <div class="picMainText">
            <div class="textInput">
              <div class="picTitle">魔法咒语:</div>
              <div class="picDescribe card-wrapper">
                <div class="picDescribeMain">
                  {{ imageData.prompts }}
                </div>
              </div>
            </div>
            <div class="textInfo">
              <div class="textInfoKey">
                <div class="itemKey">任务ID：</div>
                <div class="itemKey">任务状态：</div>
                <div class="itemKey">创作时间：</div>
                <!-- <div class="itemKey">{{ imageData.completeTime ? '绘画结束时间：' : ' ' }}</div> -->
              </div>
              <div class="textInfoValue">
                <div class="itemKey">{{ subTaskId2Show(imageData.taskId) }}</div>
                <div class="itemKey">{{ status }}</div>
                <div class="itemKey">{{ getHMS(imageData.createdTime) }}</div>
                <!-- <div class="itemKey">{{ imageData.completeTime ? getHMS(imageData.completeTime) : ' ' }}</div> -->
              </div>
            </div>
            <div class="textbutton">
              <el-button type="primary" :disabled="submitFlag || imageData.status == 0" class="button-new"
                @click="handlerPic('edit')">重新生成</el-button>
              <el-button slot="reference" type="primary" :disabled="imageData.status == 0" class="button-new"
                @click="eventConfirm">删除</el-button>
              <!-- <el-popover placement="bottom" width="160" v-model="visible">
                <p>确定删除您的绘画作品吗？</p>
                <div style="text-align: right; margin: 0">
                  <el-button type="primary" @click="visible = false">取消</el-button>
                  <el-button type="primary" @click="deleteImg">确定</el-button>
                </div>
                <el-button slot="reference" type="primary" :disabled="!isShowBtn" class="button-new">删除</el-button>
              </el-popover> -->
            </div>
            <!-- <div class="textbutton">
            <button>重新生成</button>
            <button>删除</button>
          </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="picSearch">
      <a class="btn poneBtn" href="javascript:;" v-if="isPone" :disable="isShow == 3" autocomplete="off" title="长按开始录音"
        @touchstart="keyDown" @touchend="keyUp">
        <div v-if="isShow == 1">
          <svg t="1692411515096" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="4032" width="3.8vw" height="3.8vw">
            <path
              d="M576 763.733333V853.333333h-85.333333v-89.6C362.666667 746.666667 264.533333 640 256 512h85.333333c8.533333 93.866667 93.866667 170.666667 192 170.666667s179.2-76.8 192-170.666667h85.333334c-12.8 128-110.933333 234.666667-234.666667 251.733333zM533.333333 128C593.066667 128 640 174.933333 640 234.666667v256c0 59.733333-46.933333 106.666667-106.666667 106.666666S426.666667 550.4 426.666667 490.666667v-256C426.666667 174.933333 473.6 128 533.333333 128z"
              fill="#47af2c" p-id="4033"></path>
          </svg>
        </div>
        <div v-if="isShow == 2">
          <svg t="1692429135550" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="4039" width="3.8vw" height="3.8vw">
            <path
              d="M458.12736 1023.55456h107.74528v-134.67648H458.12736zM512 700.32896c89.25184 0 161.6128-72.36096 161.6128-161.6128V161.6128C673.6128 72.36096 601.25184 0 512 0S350.3872 72.36096 350.3872 161.6128v377.09824c0 89.25696 72.36096 161.61792 161.6128 161.61792z"
              p-id="4040" fill="#1afa29"></path>
            <path
              d="M942.97088 457.9072h-80.80896c0 193.08032-157.0816 350.16192-350.16192 350.16192-193.08032 0-350.16192-157.0816-350.16192-350.16192H81.02912c0 238.01344 192.95744 430.97088 430.97088 430.97088 238.01344 0 430.97088-192.95744 430.97088-430.97088z"
              p-id="4041" fill="#1afa29"></path>
          </svg>
        </div>
        <div v-if="isShow == 3">
          <svg t="1692411515096" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="4032" width="3.8vw" height="3.8vw">
            <path
              d="M576 763.733333V853.333333h-85.333333v-89.6C362.666667 746.666667 264.533333 640 256 512h85.333333c8.533333 93.866667 93.866667 170.666667 192 170.666667s179.2-76.8 192-170.666667h85.333334c-12.8 128-110.933333 234.666667-234.666667 251.733333zM533.333333 128C593.066667 128 640 174.933333 640 234.666667v256c0 59.733333-46.933333 106.666667-106.666667 106.666666S426.666667 550.4 426.666667 490.666667v-256C426.666667 174.933333 473.6 128 533.333333 128z"
              fill="#8a8a8a" p-id="4033"></path>
          </svg>
        </div>
      </a>
      <a class="btn" href="javascript:;" v-else :disable="isShow == 3" autocomplete="off" title="长按开始录音"
        @mousedown="keyDown" @mouseup="keyUp">
        <div v-if="isShow == 1" class="imgContener1"></div>
        <div v-if="isShow == 2" class="imgContener2"></div>
        <div v-if="isShow == 3" class="imgContener3"></div>
      </a>
      <el-input ref="contentInput" class="textInput" type="textarea" :autosize="{ maxRows: 6 }" :placeholder="currentPrompt"
        @input="handleInput"
        v-model="getImgQuery.prompts">
        <!-- @mouseover.native="stopPromptTemplateRotation" @mouseout.native="startPromptTemplateRotation" -->
      </el-input>
      <!-- <textarea class="textInput"></textarea> -->
      <el-button type="primary" class="button-new" @click="handlerPic('add')"
        :disabled="submitFlag || imageData.status == 0">生成</el-button>
    </div>
  </div>
</template>

<script>
import { Get, Patch, Post, Delete, Put } from "utils/axios";
import { mapState } from "vuex";
import { SetVideoStatistics } from "api/common";
import { deletePainting, downPic, reqGetPromptTemplates, reqGetRandomPromptTemplates } from "@/api/resource.js";
export default {
  props: ["imageData", "isShowBtn"],
  data() {
    return {
      startTime: "",
      endTime: "",
      recordTimer: "",
      recordWarmTimer: "",
      stopRecordTimer: "",
      code: 1,
      recorder: "",
      isShow: 1,
      visible: false,
      promptTemplate: [''],
      currentPromptIndex: 0, // 当前提示词索引
      currentPrompt: '请输入魔法咒语...', // 当前提示词
      promptTimer: null, // 计时器
      getImgQuery: {
        prompts: "",
        taskId: "",
      },
      submitFlag: false,
      recordPermission: false,
    };
  },
  mounted() {
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia;
    // if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    //   navigator.mediaDevices.getUserMedia({ audio: true }).then(
    //   (stream) => {
    //     console.log('页面初始化，判断权限');
    //     this.recordPermission = true;
    //     // 需要给录音权限去掉
    //   });
    // }
    if (navigator.userAgent.toLowerCase().indexOf("micromessenger") == -1 && navigator.permissions) { // 非微信浏览器
      navigator.permissions.query({ name: "microphone" }).then((result) => {
        if (result.state === "granted") {
          this.recordPermission = true;
        } else if (result.state === "prompt") {
          this.recordPermission = false;
        }
      });
    }

    this.reqGetRandomPromptTemplates();
    // setTimeout(() => {
    //   this.startPromptTemplateRotation();
    // }, 1000);
  },
  computed: {
    ...mapState(["accountInfo", "isLogin"]),
    status() {
      if (this.imageData && this.imageData.status == 0) {
        return "绘画中";
      } else if (this.imageData && this.imageData.status == 1) {
        return "绘画成功";
      } else if (this.imageData && this.imageData.status == 3) {
        return "绘画失败";
      }
    },
    btnstatus() {
      if (this.isShow == 1) {
        return "record";
      } else if (this.isShow == 2) {
        return "stop";
      } else if (this.isShow == 3) {
        return "pause";
      }
    },
    isPone() {
      return /Mobi|Android|iPhone/i.test(navigator.userAgent);
    },
  },
  methods: {

    handleInput(value) {
      if (!value) {
        this.reqGetRandomPromptTemplates();
      }
    },

    startPromptTemplateRotation() {
      if (this.promptTimer) return;
      if (this.currentPromptIndex == 0) {
        this.currentPromptIndex = Math.floor(Math.random() * this.promptTemplate.length);
        this.currentPrompt = this.promptTemplate[this.currentPromptIndex];
      }
      this.promptTimer = setInterval(() => {
        this.currentPromptIndex = Math.floor(Math.random() * this.promptTemplate.length);
        this.currentPrompt = this.promptTemplate[this.currentPromptIndex];
      }, 10000);
    },
    stopPromptTemplateRotation() {
      if (this.promptTimer) {
        window.clearInterval(this.promptTimer);
        this.promptTimer = null;
      }
    },

    async reqGetRandomPromptTemplates() {
      let prompts = await reqGetRandomPromptTemplates();
      // this.promptTemplate = prompts.data;
      this.currentPrompt = prompts.data;
    },

    //鼠标按下事件
    keyDown(event) {
      console.log("===========================================================")
      console.log("按下唤起");
      console.log("是否有录音权限", this.recordPermission);
      console.log("设备录音权限", navigator.getUserMedia);
      let _this = this;
     
      if (!this.isLogin) {
        console.log("未登录拦截");
        this.$emit("openLogin");
        this.isShow = 3;
        return;
      }
      if (!window.MediaRecorder && !navigator.mediaDevices.getUserMedia) {
        this.isShow = 3;
        this.$message.error("您的浏览器不支持录音功能");
        return;
      }

       if (!this.recordPermission) { // 没有录音权限，向用户询问
         if (navigator.userAgent.toLowerCase().indexOf("micromessenger") != -1) { // 微信浏览器
            navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
              console.log('已点击允许,开启成功');
              _this.recordPermission = true;
            }, (reason) => {
              (this.isShow = 3), this.$message.error("请检查您的麦克风和音频音量");
            });
         } else {
            console.log("非微信浏览器");
            navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
              console.log('已点击允许,开启成功');
              _this.recordPermission = true;
            }, (reason) => {
              (this.isShow = 3), this.$message.error("请检查您的麦克风和音频音量");
            });
         }
         return;
      }

      event.preventDefault();
      this.startTime = new Date().getTime() / 1000;
      if (this.isShow == 3) {
        this.isShow == 1;
        return;
      }
      this.startRecording();
    },
    //鼠标抬起事件
    async keyUp() {
      console.log("抬起结束");
      this.isSave = false;
      if (this.isShow == 3) return;
      this.endTime = new Date().getTime() / 1000;
      console.log("录音时长", (this.endTime - this.startTime) / 1000);
      if (this.endTime - this.startTime < 1) {
        this.$message.error("说话时间太短");
        this.isShow = 1;
        this.isShow = 1;
        this.isSave = true;
        this.stopRecord();
        return;
      }

      this.stopRecord();
    },
    //开始录音
    startRecording(type) {
      console.log("录音唤起");
      navigator.mediaDevices.getUserMedia({ audio: true }).then(
        (stream) => {
          navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
          if(navigator.vibrate) {
            navigator.vibrate(120);
          }
          console.log("15秒录音定时器", this.recordTimer);
          if (!this.recordTimer) {
            this.recordTimer = setTimeout(() => {
              console.log("15秒录音定时器提示");
              navigator.vibrate(120);
              this.$message.warning("语音输入时长剩余5秒");
            }, 15000);
          }

          console.log("20秒录音定时器", this.recordWarmTimer);
          if (!this.recordWarmTimer) {
            this.recordWarmTimer = setTimeout(() => {
              console.log("20秒录音定时器提示");
              navigator.vibrate(500);
              this.$message.warning("语音输入已结束");
              this.keyUp();
              return;
            }, 20000);
          }

          this.recorder = new MediaRecorder(stream);
          this.isShow = 2;
          this.recorder.addEventListener("dataavailable", this.saveRecord);
          this.recorder.start();
          console.log("录音事件绑定成功");
          
        },
        (reason) => {
          (this.isShow = 3), this.$message.error("请检查您的麦克风和音频音量");
        }
      );
    },
    //保存录音
    saveRecord(e) {
      console.log("isSave", this.isSave);
      if (this.isSave == true) return;
      console.log("上传录音开始");
      this.isShow = 3;
      var formData = new FormData();
      const fileOfBlob = new File([e.data], new Date().getTime() + ".mp3");
      formData.append("file", fileOfBlob);
      let url = "/short/speechRecognition";
      return Post(url, formData)
        .then((response) => {
          this.code = response.code;
          if (this.code == 15001) {
            this.$message.error("语音内容无法识别，记得提高音量");
            console.log(response.message);
            this.isShow = 1;
            return;
          }
          this.isShow = 1;
          if (response.data && response.data.text != "") {
            this.getImgQuery.prompts = response.data.text;
          } else {
            this.$message.error("录音识别内容为空");
          }
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    // 录音停止，清除录音状态
    stopRecord() {
      console.log("调用stopRecord方法");
      console.log("recorder", this.recorder)
      window.clearTimeout(this.recordTimer);
      this.recordTimer = null;
      window.clearTimeout(this.recordWarmTimer);
      this.recordWarmTimer = null;
      window.clearTimeout(this.stopRecordTimer);
      this.stopRecordTimer = null;
      
      this.isShow = 1;
      if (this.recorder) {
        console.log("1、recorder.state", this.recorder.state)
        if(this.recorder.state == 'inactive'){
          console.log("====== 1、调用stopRecord方法，但是状态为激活状态 ======");
          this.recorder.stop();
          console.log("2、recorder.state", this.recorder.state);
        } else {
          console.log("====== 2、调用stopRecord方法，但是状态为非激活状态 ======");
          this.recorder.stop();
          console.log("3、recorder.state", this.recorder.state)
        }
        this.recorder.stream.getTracks()[0].stop();
        this.recorder = null;
      } else {
        // debugger;
        console.log("this.recorder为空");
        console.log("this.isShow", this.isShow);
        this.stopRecordTimer = setTimeout(() => {
          this.recorder.stop();
          this.recorder.stream.getTracks()[0].stop();
          this.recorder = null;
        }, 100);
      }
    },

    //删除
    async deleteImg() {
      let { taskId } = this.imageData;
      let res = await deletePainting({ taskId });
      console.log(res);
      if (res.code == 0) {
        this.$parent._deleteImageDate(taskId);
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      }
      // visible = false
    },
    //删除二次确定
    eventConfirm() {
      this.$confirm("此操作将永久删除该绘画, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.deleteImg();
      });
    },
    subTaskId2Show(taskId) {
      return taskId.indexOf("_") != -1 ? taskId.substr(0, taskId.indexOf("_")) : taskId;
    },
    //格式化时间戳
    getHMS(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "/";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "/";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";

      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    //点击提交生成新照片或者更新照片
    handlerPic(type) {
      if (!this.isLogin) {
        this.$emit("openLogin");
      } else {
        if (type == "add") {
          if (this.getImgQuery.prompts.trim() == "") {
            // this.$message.warning("请输入魔法咒语");
            this.getImgQuery = {
              prompts: this.currentPrompt
            };
            this.submitFlag = true;
            this.$emit("getImg", this.getImgQuery, "add");
          } else {
            this.submitFlag = true;
            this.$emit("getImg", this.getImgQuery, "add");
          }
        } else {
          this.getImgQuery = {
            prompts: this.imageData.prompts,
            parentId: this.imageData.taskId,
          };
          this.submitFlag = true;
          this.$emit("getImg", this.getImgQuery);
        }
      }
    },
    /**
     * 点击预览添加下载按钮
     */
    clickImage() {
      this.$nextTick(() => {
        let wrapper = document.getElementsByClassName(
          "el-image-viewer__actions__inner"
        );
        let downImg = document.createElement("i");
        downImg.setAttribute("class", "el-icon-download");
        wrapper[0].appendChild(downImg);
        if (wrapper.length > 0) {
          this.wrapperElem = wrapper[0];
          this.cusClickHandler();
        }
      });
    },
    /**
     * 点击下载图片
     */
    cusClickHandler() {
      this.wrapperElem.addEventListener("click", this.hideCusBtn);
    },
    async hideCusBtn(e) {
      let className = e.target.className;
      if (className === "el-icon-download") {
        let imgUrl = document.getElementsByClassName(
          "el-image-viewer__canvas"
        )[0].children[0].src;
        console.log(imgUrl); //图片地址
        let taskId = this.imageData.taskId;
        let _taskId = taskId.indexOf("_") != -1 ? taskId.substr(0, taskId.indexOf("_")) : taskId;

        SetVideoStatistics({
          resourceId: _taskId,
          level: 1,
          subject: 1,
          type: "download",
        });

        let imgData = this.imageData.imageUrls.find(
          (item) => item.paintingRecordUrl == imgUrl
        );
        console.log(this.imageData);
        let paintingRecordId = imgData.paintingRecordId;
        let paintingRecordUrl = imgData.paintingRecordUrl;
        let reg2 = /([^/]+)$/;
        let fileName = paintingRecordUrl.match(reg2)[1];
        fileName = fileName || "download-image";
        let query = {
          paintingRecordId,
          taskId,
          fileName,
        };
        let res = await downPic(query);
      }
    },
  },
  beforeDestroy() {
    this.stopPromptTemplateRotation();
  },
};
</script>

<style lang="less" >
.picRightContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .picMainContainer {
    width: 100%;
    height: 82%;
    display: flex;
    justify-content: center;
    align-items: center;

    .picMain {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      border: 0.03rem solid #ade787;
      background-color: rgba(255, 255, 255, 0.7);

      .picMainItem {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 3.5vw;

        .picContainer {
          width: 30vw;
          height: 30vw;
          // background: #ccc;
          display: flex;
          flex-wrap: wrap;
          border-radius: 20px;
          border: 5px solid #ade787;
          overflow: hidden;

          .picMainPic {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1vw;

            .picStauts {
              width: 12vw;
              height: 12vw;
              font-weight: 500;
              line-height: 16vw;
              text-align: center;
              position: relative;
              transform: translateY(-1vw);
            }

            .picLoading {
              background-image: url(../../assets/ui_ai-fe/05.gif);
              background-size: 100%;
              background-repeat: no-repeat;

              &:after {
                width: 100%;
                color: "#448438";
                display: block;
                content: "正在绘画中...";
                font-size: 1.2vw;
                position: absolute;
                top: 5.5vw;
                color: #448816;
              }
            }

            .picError {
              background-image: url("../../assets/paintingFailed.jpg");
              background-size: 100%;
              background-repeat: no-repeat;

              &:after {
                width: 100%;
                color: "#448438";
                display: block;
                content: "绘图失败啦！请重试";
                font-size: 1.2vw;
                position: absolute;
                top: 5.5vw;
                color: #448816;
              }
            }

            .picFail {
              font-size: 30px;
              font-weight: 500;
              width: 100%;
              height: 20%;
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
            }
          }

          .img-pic {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;

            .el-image {
              width: 50%;
              height: 50%;
            }
          }
        }
      }

      .picMainText {
        width: 24vw;
        height: 30vw;
        padding: 1vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;
        border: 5px solid #ade787;

        .textInput {
          width: 100%;
          height: 12vw;
          text-align: left;

          // background: pink;
          .picTitle {
            margin-bottom: 1vw;
            font-size: 0.3rem;
            color: #448816;
          }

          .picDescribe {
            color: #448816;
            width: 95%;
            margin: 0 auto;
            min-height: 0.5rem;
            max-height: 1.8rem;
            border: 3px solid #ade787;
            border-radius: 1vw;
            padding: 0.4vw 0.3vw 0.4vw 0.5vw;

            .picDescribeMain {
              width: 100%;
              height: 100%;
              overflow-y: auto;
              padding-right: 0.4vw;
              color: #448816;
            }

            .picDescribeMain::-webkit-scrollbar {
              display: block;
              width: 10px;
            }

            // 伪 ::scrollbar-thumb 元素设置

            .picDescribeMain::-webkit-scrollbar-thumb {
              display: block;
              border-radius: 8px;
              background-color: #448816;
            }
          }
        }

        .textInfo {
          width: 100%;
          height: 30%;
          display: flex;
          justify-content: space-between;
          font-size: 1vw;

          .textInfoKey {
            height: 100%;
            width: 34%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: end;

            .itemKey {
              color: #448816;
              width: 100%;
              text-align: justify;
              text-align-last: justify;
              height: 25%;
            }
          }

          .textInfoValue {
            height: 100%;
            width: 65%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: start;

            .itemKey {
              color: #448816;
              text-align: left;
              height: 25%;
            }
          }
        }

        .textbutton {
          width: 100%;
          height: 15%;
          text-align: center;
          display: flex;
          align-items: center;

          .button-new {
            width: 6vw;
            height: 2.2vw;
            line-height: 1.8vw;
            border-radius: 1.1vw;
            border: 3px solid #448816;
            font-size: 1vw;
            font-weight: 600;
            margin-left: 1vw;
          }
        }
      }
    }

    .tree {
      width: 32vw;
      height: 32vw;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        width: 100%;
      }
    }

    .tree:after {
      content: "激发孩子无限创意，开启AI探索之旅!";
      position: absolute;
      bottom: -2vh;
      left: -5%;
      color: #448816;
      font-size: 1.8vw;
      display: block;
      width: 110%;
    }
  }

  .picSearch {
    width: 100%;
    max-height: 20%;
    min-height: 10%;
    display: flex;
    padding: 0.2vw;
    // margin: 10px;
    border: 1px solid #fff;
    border-radius: 20px;
    justify-content: space-around;
    background-color: #ffffff;
    align-items: center;

    .button-new {
      width: 4vw;
      height: 2.2vw;
      line-height: 1.8vw;
      border-radius: 1.1vw;
      border: 3px solid #448816;
      font-weight: 600;
      font-size: 1vw;
    }

    .btn {
      margin-left: 0.1vw;
    }

    .poneBtn {
      -webkit-touch-callout: none; // 禁止长按呼出菜单
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .poneImg {
      pointer-events: none;
      width: 3.5vw;
      height: 3.5vw;
    }

    .imgContener1 {
      -webkit-touch-callout: none;
      width: 3.5vw;
      height: 3.5vw;
      background-image: url("../../assets/语音.jpg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-color: #fff;
    }

    .imgContener2 {
      -webkit-touch-callout: none;
      width: 3.7vw;
      height: 3.7vw;
      background-image: url("../../assets/麦克风录音中.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-color: #fff;
    }

    .imgContener3 {
      -webkit-touch-callout: none;
      width: 3.5vw;
      height: 3.5vw;
      background-image: url("../../assets/语音灰.jpg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-color: #fff;
    }

    .textInput {
      width: 85%;
      min-height: 2vh;
      max-height: 13vh;
      // height: 100%;
      overflow-y: auto;
      line-height: 2vh;
      font-size: 1.5vh;
      // border: none;
      margin-left: 0.75vw;

      .el-textarea__inner {
        height: auto;
        padding: 5px 0;
      }
    }

    // .textInput::-webkit-scrollbar {
    //   display: block;
    //   width: 10px;
    // }

    // // 伪 ::scrollbar-thumb 元素设置

    // .textInput::-webkit-scrollbar-thumb {
    //   display: block;
    //   border-radius: 8px;
    //   background-color: #448816;
    // }
  }
}

.page-wrapper-box {
  button {
    border: none;
  }
}
</style>