/** 
 * @Desc: 资源模块列表
 */
<template>
  <div class="resource-page-wrapper" v-loading="loading">
    <div v-if="dataList.length" id="resourceContent" class="resource-content">
      <ResourceItem
        v-for="(item, index) in dataList"
        :key="index"
        :item="item"
        component-type="moduleList"
      />
      <div v-if="scrollLoading" class="scroll-loading">
        <img class="img-scroll-loading loading-animation" src="../../assets/loading.png" alt="">
      </div>
    </div>
    <div v-if="!loading && !dataList.length" class="no-data-box">
      <!-- <div class="no-data-box card-wrapper"> -->
      <!-- <img src="../../assets/no-data.png" alt="" class="img-no-data">
      <p class="no-data-title">暂无数据</p>-->
    </div>
  </div>
</template>
<script>
import ResourceItem from "components/resource-item";
import { reqGetResourcePackingList } from "api/resource";
import store from "store";

import {
  localStorageUtil,
  debounce,
  throttle,
  GetPageType,
  GetRandomNum
} from "utils/util";
export default {
  inject: ["reload"],
  components: {
    ResourceItem
  },
  data() {
    return {
      loading: true,
      dataList: [],
      pageType: null,
      scrollLoading: false,
      pageTotal: 0,
      pageSize: 12,
      pageNum: 1
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        let pageType = GetPageType(val);
        if (this.pageType && this.pageType !== pageType) {
          this._resetPage();
          this.reload();
        }
        this.pageType = pageType;
      }
    }
  },
  methods: {
    /**
     * 页面重置
     * **/
    _resetPage() {
      this.loading = true;
      this.dataList = [];
      this.scrollLoading = false;
      this.pageNum = 1;
      $("#app")[0].scrollIntoView({ behavior: "instant" });
    },
    /**
     * 数据获取
     * **/
    _getData() {
      let obj = {
        gradeId: 0,
        typeId: this.pageType
      };
      if (this.pageNum === 1) {
        this.loading = true;
      }
      return reqGetResourcePackingList(obj)
        .then(res => {
          this.loading = false;
          this.scrollLoading = false;
          if (res.code === 0) {
            this.pageTotal = res.data.length;
            let _list = [...res.data];
            for (let item of _list) {
              item.randomNum = GetRandomNum(1, 10);
              item.randomNum = item.randomNum * 50;
              item.pageType = item.typeId;
            }
            this.dataList = _list;
            setTimeout(() => {
              let ele_list = $("#resourceContent .resource-item-inner").each(
                function() {
                  $(this).removeClass("wobblyBouncy");
                }
              );
            }, 1000);
          } else {
            this.$message({
              type: "error",
              message: "资源获取失败",
              duration: 2000
            });
          }
        })
        .catch(err => {
          this.loading = false;
          this.scrollLoading = false;
          console.log("资源获取失败", err);
        });
    },
    /**
     * 监听scroll事件
     * **/
    _handleWinScroll(e) {
      if (
        this.loading ||
        this.scrollLoading ||
        (this.dataList.length && this.dataList.length >= this.pageTotal)
      ) {
        return;
      }
      var nScrollHight = 0;
      var nScrollTop = 0;
      let body_h = $("body").height();
      let doc_h = $(document).height();
      let scroll_t = $(document).scrollTop(); //滚动到的当前位置
      // console.log('???????????', doc_h - body_h + scroll_t);
      if (doc_h - body_h - scroll_t < 20) {
        this.scrollLoading = true;
        this.pageNum++;
        this._getData();
        console.log("is end");
      }
    }
  },
  created() {
    this._getData();
  },
  mounted() {
    this.$nextTick(() => {
      $("#app")[0].scrollIntoView({ behavior: "instant" });
      // window.addEventListener('scroll', this._handleWinScroll);
    });
  },
  destroyed() {
    // window.removeEventListener('scroll', this._handleWinScroll);
  }
};
</script>
<style lang="less">
.resource-page-wrapper {
  // width: 1100px;
  // width: 1210px;
  // width: 12.1rem;
  width: 11.5rem;
  margin: auto;
  // padding-top: 245px;
  // padding-top: 256px;
  padding-top: 2.25rem;
  // padding-top: 13.5%;
  // min-height: calc(100vh - 75px) !important;
  min-height: calc(100vh - 78px) !important;
  position: relative;
  z-index: 10;

  .resource-content {
    padding: 50px;

    position: relative;
    .scroll-loading {
      width: 100%;
      height: 50px;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: -50px;
      .img-scroll-loading {
        width: 50px;
        height: 50px;
      }
    }
  }
  .no-data-box {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
  .resource-name {
    margin-top: 6px;
    font-size: 18px !important;
    font-size: 0.18rem !important;

    display: inherit;
  }
}
</style>